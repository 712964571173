@media (min-width: 768px){.tag.taxo:not(.small) {
  --tag-padding: 2.4rem;
  min-width: 15rem;
}

.button.ico-only {
  --btn-size: 6.2rem;
  --btn-padding: 2rem;
  --btn-radius: 1rem;
  max-width: var(--btn-size);
  max-height: var(--btn-size);
  width: var(--btn-size);
  height: var(--btn-size);
}

:root {
  --container-padding: 3rem;
}

.container,
.container-small,
.container-big {
  --container-size: 100%;
}

.container-big {
  --container-big-padding: 4.8rem;
}

.col-sm-1 {
  --grid-col: 1;
}

.col-sm-2 {
  --grid-col: 2;
}

.col-sm-3 {
  --grid-col: 3;
}

.col-sm-4 {
  --grid-col: 4;
}

.col-sm-5 {
  --grid-col: 5;
}

.col-sm-6 {
  --grid-col: 6;
}

.col-sm-7 {
  --grid-col: 7;
}

.col-sm-8 {
  --grid-col: 8;
}

.col-sm-9 {
  --grid-col: 9;
}

.col-sm-10 {
  --grid-col: 10;
}

.col-sm-11 {
  --grid-col: 11;
}

.col-sm-12 {
  --grid-col: 12;
}

:root {
  --big-title-size: 4.8rem;
  --h1-size: 4rem;
  --h2-size: 3.2rem;
  --h2-content-size: 2.4rem;
  --h3-size: 2.4rem;
  --h3-content-size: 1.8rem;
  --h4-size: var(--h3-size);
  --h5-size: var(--h3-size);
  --h6-size: var(--h3-size);
  --big-title-line-height: 5.3rem;
  --h1-line-height: 4.8rem;
  --h2-line-height: 3.2rem;
  --h2-content-line-height: 3.2rem;
  --h3-line-height: 3.2rem;
  --h3-content-line-height: 2.4rem;
  --h4-line-height: var(--h3-line-height);
  --h5-line-height: var(--h3-line-height);
  --h6-line-height: var(--h3-line-height);
  --marg-btm-title: 3.2rem;
}

.rounded-img:not(.big) {
  --round-size: 11rem;
}

.rounded-img .big {
  --round-size: 25.6rem;
}

.hidden-tablet {
  display: none;
}

.card,
.card img,
.card .icon-arrow-right {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  -webkit-transform: rotateZ("0");
  transform: rotateZ("0");
}

.card-domain {
  --card-radius: 0.8rem;
}

.card-domain:not(.light):hover {
  --card-bg: rgba(223, 115, 0, 0.2);
}

.card-domain:not(.light):hover .icon-arrow-right {
  margin-right: 1.2rem;
}

.card-domain.light:hover {
  --card-bg: #FCF1E5;
}

.card-city:hover img,
.card-profession:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.card-city:hover .card-action,
.card-profession:hover .card-action {
  background-color: #fcf1e5;
}

.card-city:hover .icon-arrow-right,
.card-profession:hover .icon-arrow-right {
  margin-right: 1.2rem;
}

.card-actu:hover {
  --card-bg: #FCF1E5;
}

.card-actu:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.searchform {
  --searchform-grid-columns: 1fr auto;
  --searchform-grid-rows: 1fr;
  -webkit-column-gap: 1.6rem;
  -moz-column-gap: 1.6rem;
  column-gap: 1.6rem;
}

.searchform .form-group:first-of-type {
  border-right: 1px solid #f4f4f4;
}

.list label {
  display: none;
}

.term-list,
.term-list-double {
  --column-count: 2;
}

:root {
  --header-padding: 3.2rem;
  --logo-size: var(--ico-size-xl);
  --header-size: 11.6rem;
  --header-gap: 1.2rem;
}

#primary-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sub-item {
  position: absolute;
  top: calc(100% + .2rem);
  left: 0;
  min-width: 100%;
  border: 1px solid #f4f4f4;
  border-radius: .8rem;
}

.nav-item:not(.is-btn) {
  border-radius: .4rem;
}

.nav-item:not(.is-btn):hover,
.nav-item:not(.is-btn):target,
.nav-item:not(.is-btn):focus-visible,
.nav-item:not(.is-btn):active {
  background-color: #e7efeb;
}

.section-hero:not(.hero-hp) {
  --hero-grid-columns-wrapper: 1fr;
  --hero-grid-rows-wrapper: 24.7rem calc(6.2rem + 1.6rem);
  --hero-img-height: 32.5rem;
  --hero-marg-btm: calc(4rem * 2);
}

.section-hero:not(.hero-hp) .searchform-wrapper {
  grid-column: 1/3;
  grid-row: 2/4;
}

.section-hero.hero-hp {
  --hero-grid-columns-wrapper: 25vw 1fr;
  --hero-grid-rows-wrapper: 16.5rem 16.5em 6em;
  --hero-img-height: 52.5rem;
  --hero-padding-left: 6.6rem;
  --hero-marg-btm: calc(6rem);
}

.section-hero.hero-hp .searchform-wrapper {
  grid-column: 2/3;
  grid-row: 3/4;
}

.section-hero .searchform-title {
  grid-column: 2/3;
  grid-row: 2/4;
  padding: 5.2rem 1rem 0 var(--hero-padding-left);
}

body:not(.fusion-form) .hero-hp .searchform {
  padding-left: var(--hero-padding-left);
  padding-right: 0;
  padding-top: .5em;
}

.header-job {
  --header-grid-columns: 25.6rem 1fr;
  --header-grid-rows: 1fr;
  --header-grid-gap: 5.2rem;
  --header-img-width: 25.6rem;
  --header-img-height: 25.6rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.marg-btm-xl {
  --marg-btm-xl: calc(3.2rem * 3);
}}